<template>
    <div class="live-events full-height full-width">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <template v-if="competition && competition._id">
                <v-container fluid class="pa-6 overflow-y-scroll full-height full-width p-relative">
                    <v-row>
                        <v-col class="section-header d-flex align-start justify-space-between" cols="12">
                            <div class="d-flex flex-column mr-6">
                                <h3 class="mb-3">
                                    <router-link :to="{ name: 'live-competitions' }">
                                        <span class="dark--text">Competitions</span>
                                    </router-link>
                                    //
                                    <span class="primary--text">{{ competition.title }}</span>
                                </h3>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" sm="6" md="4" lg="2" v-for="room in rooms" :key="room._id">
                                    <basic-tile :item="room" @click="$router.push({ name: 'live-competitions-room', params: { competition: $route.params.competition, room: room._id } })" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-else>
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="d-flex flex-column align-center justify-center full-height">
                        <p class="text-center mb-0">There are no events happening right now.</p>
                    </div>
                </v-container>
            </template>
        </template>
    </div>
</template>

<script>
// Services
import Competition from "@/modules/app-module/competition";

// Components
import BasicTile from "@/components/global/basic-tile.vue";

export default {
    name: "live-competition",

    meta() {
        return {
            title: this.competition?.title || "Competition",
        };
    },

    props: {},

    components: {
        BasicTile,
    },

    data() {
        return {
            loading: false,
            competition: {},
            rooms: [],
        };
    },

    methods: {
        hasImage(category) {
            let image = category?.data?.image;

            return image?._id || image;
        },
        async init() {
            this.loading = true;

            try {
                this.competition = await Competition.get(this.$route.params.competition).then(({ data }) => data);

                const payload = {
                    _type: "article",
                    definition: "room",
                    "data.competition": `ObjectId(${this.$route.params.competition})`,
                };

                this.rooms = await this.$fluro.api.post("/content/_query", payload).then(({ data }) => data);

                console.log("COMPETITION", this.competition);
                console.log("ROOMS", this.rooms);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
